<template>
  <div class="login-container">
    <el-row>
      <el-col :xs="24" :sm="24" :md="12" :lg="16" :xl="16">
        <div style="color: transparent">占位符</div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" style="background: rgba(255, 255, 255, 0.8); border-radius: 4px">
        <el-form ref="formRef" :model="form" :rules="rules" class="login-form" label-position="left">
          <div class="title">Hello !</div>
          <div class="title-tips">欢迎来到可之科技后台管理系统</div>
          <el-form-item style="margin-top: 40px" prop="username">
            <el-input v-model.trim="form.username" autofocus placeholder="请输入用户名" tabindex="1" type="text">
              <template #prefix>
                <i class="el-icon-user" style="margin-left: 5px"></i>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              :key="passwordType"
              ref="passwordRef"
              @keydown.enter="login"
              v-model.trim="form.password"
              :type="passwordType"
              tabindex="2"
              placeholder="请输入密码"
            >
              <template #prefix>
                <i class="iconfont" style="margin-left: 5px">&#xe60c;</i>
              </template>
              <template #suffix>
                <i v-if="passwordType === 'password'" class="iconfont" style="margin-right: 5px" @click.stop="handlePassword">&#xe628;</i>
                <i v-else class="iconfont" style="margin-right: 5px" @click.stop="handlePassword">&#xe8e5;</i>
              </template>
            </el-input>
          </el-form-item>
          <el-button :loading="loading" class="login-btn" type="primary" @click="login" @keydown.enter="login"> 登录 </el-button>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, ref } from 'vue'
import { ElInput } from 'element-plus'
import { setAuths, setUserInfo, validPassword, validUsername } from '@/utils/utils'
import { apiGetAuth } from '@/api/user'
import http from '@/utils/http'
import { UserInfo } from '@/vo/UserInfo'
import G from '@/config/config'
import { useRouter } from 'vue-router'

const validateidentifier = (rule: unknown, value: string, callback: Function) => {
  if (!validUsername(value)) {
    callback(new Error('用户名格式不正确：最长20位，支持“A-Z”，“a-z”，“0-9”'))
  } else {
    callback()
  }
}
const validatecredential = (rule: unknown, value: string, callback: Function) => {
  if (!validPassword(value)) {
    callback(new Error('密码格式不正确：至少8位并且最长20位，支持“A-Z”、“a-z”、“0-9”、“-_@!#$%^&*”'))
  } else {
    callback()
  }
}
export default defineComponent({
  name: 'Login',
  setup() {
    const router = useRouter()
    const passwordRef = ref<InstanceType<typeof ElInput>>()
    const data = reactive({
      form: {
        username: '',
        password: '',
      },
      passwordType: 'password',
      loading: false,
      rules: {
        username: [{ required: true, trigger: 'blur', validator: validateidentifier }],
        password: [{ required: true, trigger: 'blur', validator: validatecredential }],
      },
    })

    async function handlePassword() {
      data.passwordType === 'password' ? (data.passwordType = '') : (data.passwordType = 'password')
      await nextTick()
      passwordRef.value?.focus()
    }

    function login() {
      data.loading = true
      http<UserInfo>('POST', `/correct/bs/userdomain/user/login`, {
        identifier: data.form.username,
        credential: data.form.password,
        platform: G.platform,
      })
        .then(userInfo => {
          setUserInfo(userInfo)
          apiGetAuth(userInfo.roleId).then(authTree => {
            setAuths(authTree)
            router.push({
              path: '/',
            })
          })
        })
        .finally(() => {
          data.loading = false
        })
    }

    return { ...toRefs(data), handlePassword, passwordRef, login }
  },
})
</script>

<style lang="scss" scoped>
.login-container {
  height: 100vh;
  background: url('~@/assets/login_images/background.jpg') center center fixed no-repeat;
  background-size: cover;
  .title {
    font-size: 54px;
    font-weight: 500;
    color: rgba(14, 18, 26, 1);
  }
  .title-tips {
    margin-top: 29px;
    font-size: 26px;
    font-weight: 400;
    color: rgba(14, 18, 26, 1);
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .login-btn {
    display: flex;
    width: 100%;
    height: 44px;
    align-items: center;
    justify-content: center;
    border: 0;
    font-size: 18px;
    letter-spacing: 2px;
    margin: 0 auto;
    margin-top: 45px;
    &:hover {
      opacity: 0.9;
    }
  }
  .login-form {
    position: relative;
    max-width: 100%;
    margin: calc((100vh - 425px) / 2) 10% 10%;
    overflow: hidden;
    .forget-password {
      width: 100%;
      margin-top: 40px;
      text-align: left;
      .forget-pass {
        width: 129px;
        height: 19px;
        font-size: 20px;
        font-weight: 400;
        color: rgba(92, 102, 240, 1);
      }
    }
  }
  .tips {
    margin-bottom: 10px;
    font-size: $base-font-size-default;
    color: $base-color-white;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .title-container {
    position: relative;
    .title {
      margin: 0 auto 40px auto;
      font-size: 34px;
      font-weight: bold;
      color: $base-color-blue;
      text-align: center;
    }
  }
  .svg-container {
    position: absolute;
    top: 14px;
    left: 15px;
    z-index: $base-z-index;
    font-size: 16px;
    color: #d7dee3;
    cursor: pointer;
    user-select: none;
  }
  .show-password {
    position: absolute;
    top: 14px;
    right: 25px;
    font-size: 16px;
    color: #d7dee3;
    cursor: pointer;
    user-select: none;
  }

  :v-deep(.el-form-item) {
    padding-right: 0;
    margin: 20px 0;
    color: #454545;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 2px;
    & __content {
      min-height: $base-input-height;
      line-height: $base-input-height;
    }
    & __error {
      position: absolute;
      top: 100%;
      left: 18px;
      font-size: $base-font-size-small;
      line-height: 18px;
      color: $base-color-red;
    }
    .el-input {
      box-sizing: border-box;
      input {
        height: 58px;
        padding-left: 45px;
        font-size: $base-font-size-default;
        line-height: 58px;
        color: $base-font-color;
        background: #f6f4fc;
        border: 0;
        caret-color: $base-font-color;
      }
    }
  }
}
</style>
