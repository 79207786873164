
import { reactive, toRefs, defineComponent, nextTick, ref } from 'vue'
import { ElInput } from 'element-plus'
import { setAuths, setUserInfo, validPassword, validUsername } from '@/utils/utils'
import { apiGetAuth } from '@/api/user'
import http from '@/utils/http'
import { UserInfo } from '@/vo/UserInfo'
import G from '@/config/config'
import { useRouter } from 'vue-router'

const validateidentifier = (rule: unknown, value: string, callback: Function) => {
  if (!validUsername(value)) {
    callback(new Error('用户名格式不正确：最长20位，支持“A-Z”，“a-z”，“0-9”'))
  } else {
    callback()
  }
}
const validatecredential = (rule: unknown, value: string, callback: Function) => {
  if (!validPassword(value)) {
    callback(new Error('密码格式不正确：至少8位并且最长20位，支持“A-Z”、“a-z”、“0-9”、“-_@!#$%^&*”'))
  } else {
    callback()
  }
}
export default defineComponent({
  name: 'Login',
  setup() {
    const router = useRouter()
    const passwordRef = ref<InstanceType<typeof ElInput>>()
    const data = reactive({
      form: {
        username: '',
        password: '',
      },
      passwordType: 'password',
      loading: false,
      rules: {
        username: [{ required: true, trigger: 'blur', validator: validateidentifier }],
        password: [{ required: true, trigger: 'blur', validator: validatecredential }],
      },
    })

    async function handlePassword() {
      data.passwordType === 'password' ? (data.passwordType = '') : (data.passwordType = 'password')
      await nextTick()
      passwordRef.value?.focus()
    }

    function login() {
      data.loading = true
      http<UserInfo>('POST', `/correct/bs/userdomain/user/login`, {
        identifier: data.form.username,
        credential: data.form.password,
        platform: G.platform,
      })
        .then(userInfo => {
          setUserInfo(userInfo)
          apiGetAuth(userInfo.roleId).then(authTree => {
            setAuths(authTree)
            router.push({
              path: '/',
            })
          })
        })
        .finally(() => {
          data.loading = false
        })
    }

    return { ...toRefs(data), handlePassword, passwordRef, login }
  },
})
